import { Banner, BREAKPOINTS, Button, COLORS, ComponentM, ComponentS, ComponentSStyling, ComponentTextStyle, ComponentXS, IconButton, ProfileButton, RichTextField, Size, SystemIcons, Tag, TagVariants, TooltipWrapper, Typography, useClickOutsideRef } from "@laerdal/life-react-components";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from "react";




const CreateContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  gap: 16px
`;
const CreateIcon = styled.div`
  pointer-events: none;

  display: none;

  ${BREAKPOINTS.MEDIUM} {
    display: block;
  }
`;

const CreateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const CreateInput = styled.div`
  .ql-snow .ql-editor {
    ${Typography}
  }

  .ql-snow .ql-editor p {
    ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  &.open .ql-editor {
    min-height: 108px;
  }

  &.open .ql-toolbar.ql-snow {
    display: block;
  }

  &.closed .ql-toolbar.ql-snow {
    display: none;
  }
`;

const InputRow = styled.div`
    ${BREAKPOINTS.MEDIUM}{
        flex-direction: row;
    }
    flex-direction: column;

    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
`;


const AttachmentsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  gap: 16px;
  background-color: ${COLORS.neutral_20};
  padding: 24px 30px;
`;

const AttachmentsContainerList = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: start;
  gap: 16px;
  background-color: ${COLORS.neutral_20};
  max-height: 180px;
  overflow-y: auto;
`;

const AttachmentDetails = styled.div`
  ${BREAKPOINTS.MEDIUM}{
    padding: 0px 10px;
  }
  padding: 5px 10px;

  background-color: ${COLORS.white};
  display:flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
const AttachmentHeader = styled.div`
  display:flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const Row = styled.div`
  flex-grow: 1;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Col = styled.div`
  display:flex;
  flex-direction: row;
`;

const Tooltip = styled(TooltipWrapper)`
    ${BREAKPOINTS.MEDIUM}{
        width: max-content;
    }
    width: unset;
`;

const CreateActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

type CreateCommentProps = {
  busy: boolean,
  initials: string,
  save: (comment: string, files: File[]) => void
};

const defaultToolBarOptions = [
  [{ header: [4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['clean']
];

const emptyToolBarOptions = [[]];

const CreateComment = ({ initials, save, busy }: CreateCommentProps) => {
  const { t } = useTranslation('Cases');


  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [fileSizeOverLimit, setFileSizeOverLimit] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const quill = useRef<any>();
  const ref = useClickOutsideRef(() => isEmpty() && reset(), []);
  const modules = useMemo(() => ({ toolbar: defaultToolBarOptions }), [])
  const MAX_FILE_SIZE = 30 * 1024 * 1024;

  const allowedTypes = [
    'image/jpeg', 'image/png', 'image/gif', 'image/webp',
    'video/mp4', 'video/webm', 'video/ogg',
    'application/pdf', 'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'text/plain',
  ];

  const isEmpty = () => {
    const el = document.createElement('div');
    el.innerHTML = note;
    return !el.textContent?.trim() && files.length == 0;
  }
  const reset = () => {
    setNote('');
    setOpen(false);
    quill.current?.blur();
  }

  const addFiles = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fileInputRef.current?.click();
  }

  const handleFileInputChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(e.target.files);
  };

  const handleRemoveFile = (index: number) => {
    
    const fileArray = files.filter((_, i) => i !== index)

    setFiles(fileArray);

    const totalSize = fileArray.reduce((sum, file) => sum + file.size, 0);
    const oversizedFiles = totalSize > MAX_FILE_SIZE;

    if (oversizedFiles) {
      setFileSizeOverLimit(true);       
    }
  };

  const handleFiles = (newFiles: FileList | null) => {
    if (newFiles) {
      setFiles(prevFiles => [...prevFiles, ...Array.from(newFiles)]);
    }
  }

  useEffect(() => {      
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);
    setFileSizeOverLimit(totalSize > MAX_FILE_SIZE);
  }, [files]);

  return (
    <CreateContainer>
      <CreateIcon>
        <ProfileButton onClick={() => { }} initials={initials} />
      </CreateIcon>
      <CreateContent>
        <CreateInput className={open ? 'open' : 'closed'} ref={ref}>
          <RichTextField 
            disabled={busy}
            value={note}
            size={Size.Small}
            ref={quill}
            onChange={value => setNote(value)}
            onFocus={() => setOpen(true)}
            placeholder={t('Add a reply')}
            readOnly={loading}
            modules={modules} />
          {
            open &&
            <>
              <CreateActions>
                
                <Row>
                  <Col>
                    <Button variant={'secondary'} disabled={loading || busy} onClick={(e) => addFiles(e)}>{t('Add file(s)')}</Button>
                  </Col>
                  <Col>
                    <Button variant={'tertiary'} disabled={loading || busy} onClick={() => reset()}>{t('Cancel')}</Button>
                    <Button
                      variant={'primary'}
                      disabled={
                        fileSizeOverLimit ||
                        busy ||
                          (note.replace(/<[^>]*>/g, '').trim() === '' 
                          && files.length == 0)}
                      loading={loading}
                      onClick={() => {
                        save(note, files);
                        setFiles([]);
                        reset();
                      }}>
                      {t('Send')}
                    </Button>
                  </Col>
                </Row>
                
              </CreateActions>
            </>
          }
          
          <InputRow>
            {files.length > 0 && (
              <AttachmentsContainer>
                <AttachmentHeader>
                  <ComponentXS textStyle={ComponentTextStyle.Bold}>ATTACHMENTS </ComponentXS>
                  <Tooltip label={t("These attachments can be seen and downloaded by Laerdal Support")} position="top" delay="0">
                    <SystemIcons.Information />
                  </Tooltip>
                </AttachmentHeader>
                { fileSizeOverLimit &&    
                    <ComponentM color={COLORS.critical_500}>
                      {t('Files too large! Maximum is 30MB')}
                    </ComponentM>
                }

                <AttachmentsContainerList>
                  {files.reverse().map((file, index) => (
                    <AttachmentDetails key={`${index}-${file.name}`}>
                      <IconButton variant="secondary" action={() => handleRemoveFile(index)}>
                        <SystemIcons.Close color={COLORS.neutral_500} />
                      </IconButton>

                      <ComponentS color={COLORS.neutral_500} >{file.name}</ComponentS>
                    </AttachmentDetails>
                  ))}
                </AttachmentsContainerList>
              </AttachmentsContainer>
            )}
          </InputRow>
        </CreateInput>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                multiple
                accept={allowedTypes.join(',')}
                style={{ display: 'none' }}
              />

      </CreateContent>


    </CreateContainer>
  );
}

export default CreateComment;